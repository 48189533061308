/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $( "#imprint" ).hide();
		$( "#autogyro-partner" ).hide();

		if (!Modernizr.svg) {
		  $(".logo-7g-red img").attr("src", "/app/themes/7group.aero/dist/images/7group.aero-logo-red.png");
		  $(".logo-7g-white img").attr("src", "/app/themes/7group.aero/dist/images/7group.aero-logo-white.png");
		}

		// external links
        $('a.lightbox').nivoLightbox();
        $('a[href$=".pdf"]').click(function(){open(this.href);return false;});
        $('a[href^="http"]:not(.lightbox)').newWin();

        // imprint
        $( ".btn-imprint" ).click(function() {
          $( "#imprint" ).slideDown( "fast", function() {

          $('html, body').animate({
            scrollTop: $("#imprint").offset().top
          }, 1200, function () {
            window.location.hash = "imprint";
          });

          });
        });
        
        // soft scrolling
	    var $root = $('html, body');
        $('a:not(.carousel-control)').click(function() {
          var href = $.attr(this, 'href');
          $root.animate({
            scrollTop: $(href).offset().top
          }, 700, function () {
            window.location.hash = href;
          });
        return false;
        });
          
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        


        // parallax, baby!
        $('#parallax-plane').backstretch([
          '/header/7group-2487.jpg',
          '/header/as350-8035.jpg',
          '/header/as350-7840.jpg',
          '/header/7group-3752.jpg',
          '/header/7group-1485.jpg'
        ], {centeredY: false, centeredX: true, duration: 4200, fade: 2400});

$(window).on("backstretch.before", function (e, instance, index) {
  // If we wanted to stop the slideshow after it reached the end
  if (index === 0) {
    $( "#autogyro-partner" ).fadeOut(2400);
    $( "#airbus-partner" ).fadeIn(2400);
  }
  if (index === 1) {
    $( "#autogyro-partner" ).fadeOut(2400);
    $( "#airbus-partner" ).fadeIn(2400);
  }
  if (index === 2) {
    $( "#autogyro-partner" ).fadeOut(2400);
    $( "#airbus-partner" ).fadeIn(2400);
  }
  if (index === 3) {
    $( "#autogyro-partner" ).fadeOut(2400);
    $( "#airbus-partner" ).fadeIn(2400);
  }
  if (index === 4) {
    $( "#airbus-partner" ).fadeOut(2400);
    $( "#autogyro-partner" ).fadeIn(2400);
  }
});
        
        // Packery, Baby!
        var hash = window.location.hash;
		var $container = $('#container').packery({
		  itemSelector: '.item',
		  columnWidth: '.item',
		  gutter: 0,
		  percentPosition: true,
		  isInitLayout: false
		});
		function onLayout() {
		  if (hash !== '') {
		    $('html, body').animate({
              scrollTop: $(hash).offset().top - 54
            }, 420);
          }
		}
		$container.one( 'layoutComplete', onLayout );
		$container.packery();
		$container.imagesLoaded( function() {
		  $container.packery();
		});		
		// fix block positioning
		var $window = $(window);
		$.event.special.debouncedresize.threshold = 30;
		
		$( function() {
		  var $container = $('#container');
		  var $gridSizer = $container.find('.item');
		  
		  $window.on( 'debouncedresize', function() {
		    var width = $container.parent().innerWidth();
		    var columns = Math.round( width / $gridSizer.outerWidth( true ) );
		    width = Math.floor( width / columns ) * columns;
		    $container.width( width );
		  }).trigger('debouncedresize');
		});

        // touch support via jQuery Mobile
        $('#intro').swiperight(function() {
          $('#parallax-plane').backstretch('prev');
        });
        $('#intro').swipeleft(function() {
          $('#parallax-plane').backstretch('next');
        });

        $('.nivo-lightbox-image').swiperight(function() {
	      e = jQuery.Event("keyup");
		  e.which = 37;
		  $('.nivo-lightbox-prev').trigger(e);
		});
        $('.nivo-lightbox-image').swipeleft(function() {
		  e = jQuery.Event("keyup");
		  e.which = 39;
		  $('.nivo-lightbox-next').trigger(e);
		});
		
		        
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS


/*

  var spinnerTemplate = _.template($('#spinner-template').html());
  
  
  !function() {
    var spinner = $(spinnerTemplate());
    var updateScheduled = false;
    function onscreen($el) {
      var viewportBottom = $(window).scrollTop() + $(window).height();
      return $el.offset().top <= viewportBottom;
    }
    spinner.insertAfter($('#demo').closest('.row'));
    $(window).on('scroll', function() {
      if(!updateScheduled) {
        setTimeout(function() {
          if(onscreen(spinner)) Pug.bomb(100);
          updateScheduled = false;
        }, 500);
        updateScheduled = true;
      }
    });
  }();
  */
  
  

//		var $el = $('#container');
//		var listView = new infinity.ListView($el);

        // ScrollMagic
        // 
        // https://ihatetomatoes.net/wp-content/uploads/2015/05/GreenSock-CheatsheetV1-1.pdf
        var controller = new ScrollMagic.Controller();
/*        var scene = new ScrollMagic.Scene({
                        triggerElement: "#menu-navigation",
                        triggerHook: "onLeave",
                        offset: -42,
                        duration: 42
                      })
                      .setTween("#dot-nav", 0.25, { opacity: 1 }) // trigger a TweenMax.to tween
     //                 .addIndicators({name: "1 (duration: 0)"}) // add indicators (requires plugin)
                      .addTo(controller);
*/
       var scene0 = new ScrollMagic.Scene({
                        triggerElement: "#intro",
                        triggerHook: "onEnter",
                        offset: 0,
                        duration: "200%"
                      })
                      .setTween("#parallax-plane", 0.25, { top: "40%", ease:Linear.easeNone }) // trigger a TweenMax.to tween
      //                .addIndicators({name: "1 (duration: 0)"}) // add indicators (requires plugin)
                      .addTo(controller);
/*
        var scene_krankenhaus = new ScrollMagic.Scene({
                        triggerElement: "#krankenhaus",
                        triggerHook: 0.5,
                        offset: "28%",
                        duration: "21%"
                      })
                      .setTween("#krankenhaus-plan-menschen", 0.25, { opacity: 1 }) // trigger a TweenMax.to tween
     //                 .addIndicators({name: "1 (duration: 0)"}) // add indicators (requires plugin)
                      .addTo(controller);

        var scene_krankenhaus_punkte = new ScrollMagic.Scene({
                        triggerElement: "#krankenhaus",
                        triggerHook: 0.5,
                        offset: "42%",
                        duration: "28%"
                      })
                      .setTween(".badge", 0.5, { opacity: 1 }) // trigger a TweenMax.to tween
     //                 .addIndicators({name: "1 (duration: 0)"}) // add indicators (requires plugin)
                      .addTo(controller);

        var scene_ipad = new ScrollMagic.Scene({
                        triggerElement: "#ipad",
                        triggerHook: 0.7,
                        offset: 0,
                        duration: "42%"
                      })
                      .setTween("#ipad-on", 0.5, { opacity: 1 }) // trigger a TweenMax.to tween
     //                 .addIndicators({name: "1 (duration: 0)"}) // add indicators (requires plugin)
                      .addTo(controller);
*/
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
